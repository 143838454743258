import React from "react";

const Card = ( props ) => {
  const { names, lastName, email } = props;
  return (
    <div className="card size-card">
      {
          /**
           * <img
                className="card-img-top"
                src="files/images/profile/profile-bg.jpg"
                alt="Profile Background"
            />
           */
      }
      <div className="card-body little-profile text-center">
        {
            /**
             * <div className="pro-img">
                <img src="files/images/avatares/manuel-perez.jpg" alt="User Avatar" />
                </div>
             */
        }
        <h6 className="card-title font-weight-bold">{names} {lastName}</h6>
        <p className="card-text">{email}</p>
      </div>
    </div>
  );
};

export default Card;
