import React from "react";
import { Route, Switch } from "react-router-dom";

import { LoginUser, ProfileUser, RegisterUser, RecoveryPassword, ChangePassword, LoginSodexo, RegisterSodexo } from "../auth";
import { NotFound } from "../pages";
import { PrivateRoute } from "../authenticate/PrivateRoute";

const AuthLayout = (props) => {
  return (
    <Switch>
      <Route
        exact
        strict
        path="/auth/login"
        component={(props) => <LoginUser {...props} />}
      />
      <Route
        exact
        strict
        path="/auth/sodexo/welcome"
        component={(props) => <LoginSodexo {...props} />}
      />
      <Route
        exact
        strict
        path="/auth/sodexo/register"
        component={(props) => <RegisterSodexo {...props} />}
      />
      <PrivateRoute
        exact
        strict
        path="/auth/profile"
        component={(props) => <ProfileUser {...props} />}
      />
      <Route
        exact
        strict
        path="/auth/register"
        component={(props) => <RegisterUser {...props} />}
      />
      <Route
        exact
        strict
        path="/auth/recoveryPass"
        component={(props) => <RecoveryPassword {...props} />}
      />
      <Route
        exact
        strict
        path="/auth/changePass"
        component={(props) => <ChangePassword {...props} />}
      />
      <Route component={(props) => <NotFound {...props} />} />
    </Switch>
  );
};

export default AuthLayout;
