import React from "react";
import { Done } from "@material-ui/icons";

const ProgressIndicator = (props) => {
  const { location } = props;

  let pathname = location.pathname;

  let {
    isHealth,
    isMeasurements,
    isFeeding,
    isLifestyle,
  } = false;

  switch (pathname) {
    case "/forms/health":
      isHealth = false;
      isMeasurements = false;
      isFeeding = false;
      isLifestyle = false;
      break;
    case "/forms/measurements":
      isHealth = true;
      isMeasurements = false;
      isFeeding = false;
      isLifestyle = false;
      break;
    case "/forms/feeding":
      isHealth = true;
      isMeasurements = true;
      isFeeding = false;
      isLifestyle = false;
      break;
    case "/forms/lifestyle":
      isHealth = true;
      isMeasurements = true;
      isFeeding = true;
      isLifestyle = false;
      break;
    default:
      isHealth = true;
      isMeasurements = true;
      isFeeding = true;
      isLifestyle = true;
      break;
  }

  return (
    <div className="progress-indicator">
      <div className="separator-pi"></div>
      <div className="status">
        {isHealth ? (
          <Done className="font-weight-bold big-text icon-lh-none" />
        ) : (
          1
        )}
      </div>
      <div className="separator-pi"></div>
      <div className="status">
        {isMeasurements ? (
          <Done className="font-weight-bold big-text icon-lh-none" />
        ) : (
          2
        )}
      </div>
      <div className="separator-pi"></div>
      <div className="status">
        {isFeeding ? (
          <Done className="font-weight-bold big-text icon-lh-none" />
        ) : (
          3
        )}
      </div>
      <div className="separator-pi"></div>
      <div className="status">
        {isLifestyle ? (
          <Done className="font-weight-bold big-text icon-lh-none" />
        ) : (
          4
        )}
      </div>
      <div className="separator-pi"></div>
    </div>
  );
};

export default ProgressIndicator;
