import React, { useEffect, useState } from "react";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";

import UserServices from "../../services/user.services";

const FooterNavApp = (props) => {
  const { history, location, userCurrent } = props;
  const [urlLogoClient, setUrlLogoClient] = useState("");
  const [isIntegration, setIsIntegration] = useState(false)
  const { pathname } = location;
  const { idClient } = userCurrent;
  let showFooterNav = true;
  switch (pathname) {
    case "/forms/nutrivitalPlan":
      showFooterNav = false;
      break;
    default:
      break;
  }

  /*** get image by client ***/
  useEffect(() => {
    const getLogoClient = async (clientId) => {
      if (clientId !== undefined) {
        const {data} = await UserServices.dataUserByClient(clientId);
        const { client} = data || {};
        if (client) {
          setUrlLogoClient(client.urlLogo);
        }
      }
    };
    getLogoClient(idClient)
  }, [idClient]);

  useEffect(() => {
    const integration = localStorage.getItem('integrationName')
    setIsIntegration(!!integration)
  }, [])

  return (
    showFooterNav ?
    <footer className="footer footer-wrapper">
      <div className="row">
        <div className="col-4 d-flex justify-content-center align-items-center">
          {/*
          <button
            id="backform"
            className="btn btn-xl btn-green"
            onClick={history.goBack}
          >
            <ArrowBackIos style={{color: "white"}} />
          </button>
          */}
        </div>
        <div className="col-4 d-flex justify-content-center align-items-center">
          {
            !isIntegration &&
              <img
                className="img-fluid mx-auto"
                width="70px"
                src={urlLogoClient}
                alt="Logo Empresa"
              />
          }
        </div>
        <div className="col-4 d-flex justify-content-center align-items-center">
          <button
            id="aceptform"
            className="btn btn-xl btn-green"
            type="submit"
            form="nutri-form"
          >
            <ArrowForwardIos style={{color: "white"}} />
          </button>
        </div>
      </div>
    </footer> : null
  );
};

export default FooterNavApp;
