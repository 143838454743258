import React from "react";
import { CheckCircle, Error, Info, Warning } from "@material-ui/icons";

const Alert = (props) => {
    const { message, typeAlert, view } = props;
    const getIcon = (alert) => {
        switch (alert) {
            case "danger":
                return <Error className="mr-2" />;
            case "info":
                return <Info className="mr-2" />;
            case "success":
                return <CheckCircle className="mr-2" />;
            case "warning":
                return <Warning className="mr-2" />;
            default:
                return null;
        }
    };
    if (view !== "md") {
        return (
            <>
                <div className={`alert alert-${typeAlert} top-alert-${view} col-md-4 offset-md-4`} align="center">
                    {getIcon(typeAlert)}{message}
                </div>
            </>
        );
    }
    return (
        <>
            <div className={`alert alert-${typeAlert} col-md-6 offset-md-3`} align="center">
                {getIcon(typeAlert)}{message}
            </div>
        </>
    );
};

export default Alert;
