import React, { Fragment } from "react";

const MealTimeIndicator = (props) => {
    const {
        foodTimeSelected,
        keysFoodMealTimes,
        portions,
    } = props || {};
    let mealTime = "";
    return (
        <div id="mealtime" className="mealtime-navigation">
            <div className="mealtime-wrapper">
                <h6 className="text-center text-dark font-weight-bold mt-2 pt-3">
                    ELIGE TUS PORCIONES POR TIEMPO DE COMIDA
                </h6>
                <div className="mealtime-indicator d-flex justify-content-center">
                {
                    keysFoodMealTimes.map((foodTime, index) => {
                        let compName = "";
                        let btnDisabledInit = "";
                        let separatorDisabled = "";
                        /**'breakfast', 'firstCollation', 'meal', 'secondCollation', 'dinner' */
                        switch (foodTimeSelected) {
                            case "breakfast":
                                btnDisabledInit = (index!==0) ? "disabled" : "";
                                mealTime = "DESAYUNO";
                                separatorDisabled = (index!==0) ? "mealtime-separator separator-op" : "mealtime-separator";
                                break;
                            case "firstCollation":
                                btnDisabledInit = (index!==1) ? "disabled" : "";
                                mealTime = "COLACIÓN";
                                separatorDisabled = (index!==1) ? "mealtime-separator separator-op" : "mealtime-separator";
                                break;
                            case "meal":
                                btnDisabledInit = (index!==2) ? "disabled" : "";
                                mealTime = "COMIDA";
                                separatorDisabled = (index!==2) ? "mealtime-separator separator-op" : "mealtime-separator";
                                break;
                            case "secondCollation":
                                btnDisabledInit = (index!==3) ? "disabled" : "";
                                mealTime = "COLACIÓN";
                                separatorDisabled = (index!==3) ? "mealtime-separator separator-op" : "mealtime-separator";
                                break;
                            case "dinner":
                                btnDisabledInit = (index!==4) ? "disabled" : "";
                                mealTime = "CENA";
                                separatorDisabled = (index!==4) ? "mealtime-separator separator-op" : "mealtime-separator";
                                break;
                            default:
                                break;
                        }
                        const btnClass = index % 2 !== 0 ? "btn mealtime-portion small" : "btn mealtime-portion big";
                        const btnDisabled = (keysFoodMealTimes[index] === 0) ? "disabled" : btnDisabledInit;
                        const separatorClass = (keysFoodMealTimes[index] === 0) ? "mealtime-separator separator-op" : separatorDisabled;
                        switch (index) {
                            case 0:
                                compName = "desayuno";
                                break;
                            case 1:
                                compName = "primer-colacion";
                                break;
                            case 2:
                                compName = "comida";
                                break;
                            case 3:
                                compName = "segunda-colacion";
                                break;
                            case 4:
                                compName = "cena";
                                break;
                            default:
                                break;
                        }
                        return (
                            <Fragment key={index}>
                                {(index !== 0) ? <div className={separatorClass}></div> : null}
                                <div className="mealtime-contain">
                                    {/**onClick={(e) => onClick(compName, e)} */}
                                    <button className={btnClass} disabled={btnDisabled}>{portions[foodTime].final}</button>
                                </div>
                            </Fragment>
                        );
                    })
                }
                </div>
            </div>
            <div>
                <h3 className="text-center mt-4 pb-2">{mealTime}</h3>
            </div>
            <div className="row mb-2">
                <div className="col-6 text-center text-green-nutri font-weight-bold">
                    Alimento
                </div>
                <div className="col-6 text-center text-green-nutri font-weight-bold">
                    Valor de la porción
                </div>
            </div>
        </div>
    );
};

export default MealTimeIndicator;