import Loadable from "@loadable/component";

import { Loading } from "../partials";

const LoginUser = Loadable(() => import("./LoginUser"), { Loading });

const LoginSodexo = Loadable(() => import("./LoginSodexo"), { Loading });

const RegisterSodexo = Loadable(() => import("./RegisterSodexo"), { Loading });

const ProfileUser = Loadable(() => import("./ProfileUser"), { Loading });

const RegisterUser = Loadable(() => import("./RegisterUser"), { Loading });

const RecoveryPassword= Loadable(() => import("./RecoveryPass"), { Loading });

const ChangePassword= Loadable(() => import("./ChangePass"), { Loading });

export {
    LoginUser,
    LoginSodexo,
    ProfileUser,
    RegisterUser,
    RecoveryPassword,
    ChangePassword,
    RegisterSodexo
};