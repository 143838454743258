import React from "react";
import { HashRouter } from "react-router-dom";

import Layouts from "./components/layouts/Layouts";
//  import { Header } from "./components/partials";

//  import logo from "./logo.svg";
//  import "./App.css";

const App = ( props ) => {
  return (
    <HashRouter>
      {/*<Header />*/}
      <Layouts {...props} />
    </HashRouter>
  );
};

export default App;
