import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";

import AuthServices from "../../services/auth.services";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [userCurrent, setUserCurrent] = useState({});
  useEffect(() => {
    const isAuth = async () => {
      const {isAuthenticated, dataCurrentUser} = await AuthServices.getAuth();
      setUserCurrent(dataCurrentUser)
      setIsAuth(isAuthenticated)
    };
    isAuth();
  },[]);

  if (!isAuth) return null; 
  return (
    <Route
      {...rest}
      render={props =>
        isAuth ? (
          <Component {...props} userCurrent={userCurrent} />
        ) : (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
};

export {
    PrivateRoute
};