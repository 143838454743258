import React from "react";
import { Route, Switch } from "react-router-dom";

import {
  DashboardAdmin,
  DocumentViewer,
  MenuPrint,
  NotFound,
  Privacy,
  Terms,
  Welcome,
} from "../pages";
import { PrivateRoute } from "../authenticate/PrivateRoute";

import "../../stylesheets/pageLayout.scss";

const PagesLayout = (props) => {
  return (
    <Switch>
      <PrivateRoute
        exact
        strict
        path="/pages/admin"
        component={(props) => <DashboardAdmin {...props} />}
      />
      <PrivateRoute
        exact
        strict
        path="/pages/documentViewer"
        component={(props) => <DocumentViewer {...props} />}
      />
      <PrivateRoute
        exact
        strict
        path="/pages/menuPrint"
        component={(props) => <MenuPrint {...props} />}
      />
      <Route
        exact
        strict
        path="/pages/privacy"
        component={(props) => <Privacy {...props} />}
      />
      <Route
        exact
        strict
        path="/pages/terms"
        component={(props) => <Terms {...props} />}
      />
      <PrivateRoute
        exact
        strict
        path="/pages/welcome"
        component={(props) => <Welcome {...props} />}
      />
      <Route component={(props) => <NotFound {...props} />} />
    </Switch>
  );
};

export default PagesLayout;
