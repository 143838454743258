import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

import {
  CurrentResult,
  Feeding,
  Health,
  Lifestyle,
  Measurements,
  MenuDesign,
  NutrivitalPlan,
  ShouldEat,
} from "../forms";
import { NotFound } from "../pages";

import { HeaderNavApp, FooterNavApp, Sidebar } from "../partials";

const FormsLayout = (props) => {
  return (
    <Fragment>
      <HeaderNavApp {...props} />
      <Sidebar {...props} />
      <main className="container mt-2 pt-2 pb-4">
        <Switch>
          <Route
            exact
            strict
            path="/forms/currentResult"
            component={() => <CurrentResult {...props} />}
          />
          <Route
            exact
            strict
            path="/forms/feeding"
            component={() => <Feeding {...props} />}
          />
          <Route
            exact
            strict
            path="/forms/health"
            component={() => <Health {...props} />}
          />
          <Route
            exact
            strict
            path="/forms/lifestyle"
            component={() => <Lifestyle {...props} />}
          />
          <Route
            exact
            strict
            path="/forms/measurements"
            component={() => <Measurements {...props} />}
          />
          <Route
            exact
            strict
            path="/forms/menuDesign"
            component={() => <MenuDesign {...props} />}
          />
          <Route
            exact
            strict
            path="/forms/nutrivitalPlan"
            component={() => <NutrivitalPlan {...props} />}
          />
          <Route
            exact
            strict
            path="/forms/shouldEat"
            component={() => <ShouldEat {...props} />}
          />
          <Route component={() => <NotFound {...props} />} />
        </Switch>
      </main>
      <FooterNavApp {...props} />
    </Fragment>
  );
};

export default FormsLayout;
