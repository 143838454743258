import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

import { RecordList, UsersDelete, UsersEdit, UsersList, UsersListLoad } from "../admin";
import { NotFound } from "../pages";
import { HeaderNavApp, FooterNavApp, Sidebar } from "../partials";

const AdminLayout = (props) => {
  return (
    <Fragment>
        <HeaderNavApp {...props} />
        <Sidebar {...props} />
        <main className="container mt-2 pt-2">
            <Switch>
            <Route
                exact
                strict
                path="/admin/recordList"
                component={(props) => <RecordList {...props} />}
            />
            <Route
                exact
                strict
                path="/admin/usersDelete"
                component={(props) => <UsersDelete {...props} />}
            />
            <Route
                exact
                strict
                path="/admin/usersEdit"
                component={(props) => <UsersEdit {...props} />}
            />
            <Route
                exact
                strict
                path="/admin/usersList"
                component={(props) => <UsersList {...props} />}
            />
            <Route
                exact
                strict
                path="/admin/usersListLoad"
                component={(props) => <UsersListLoad {...props} />}
            />
            <Route component={(props) => <NotFound {...props} />} />
            </Switch>
        </main>
        <FooterNavApp {...props} />
    </Fragment>
  );
};

export default AdminLayout;
