import Loadable from "@loadable/component";

import { Loading } from "../partials";

const RecordList = Loadable(() => import("./RecordList"), { Loading });

const UsersDelete = Loadable(() => import("./UsersDelete"), { Loading });

const UsersEdit = Loadable(() => import("./UsersEdit"), { Loading });

const UsersList = Loadable(() => import("./UsersList"), { Loading });

const UsersListLoad = Loadable(() => import("./UsersListLoad"), { Loading });

export {
    RecordList,
    UsersDelete,
    UsersEdit,
    UsersList,
    UsersListLoad
};