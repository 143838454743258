const WEB_API = process.env.API;

const dataUserByClient = async (clientId) => {
  let respDataUser = {};
  try {
    const token = localStorage.getItem('token');
    const integrationName = localStorage.getItem('integrationName')
    const options = {
      headers:{
        'Authorization': `Bearer ${token}`,
      }
    };
    const response = await fetch(`${WEB_API}/usersApp/client/info?` + new URLSearchParams({
      integration: integrationName
    }), options);
    if (response.status === 200) {
        respDataUser = await response.json();
        return respDataUser;
      }
      respDataUser = await response.json();
      return respDataUser;
  } catch (error) {
    console.log('error: ', error);
  }
};

const getAdByClientID = async (clientId) => {
  let respDataUser = {};
  try {
    const response = await fetch(`${WEB_API}/clients/banners/${clientId}`);
    if (response.status === 200) {
      respDataUser = await response.json();
      return respDataUser;
    }
    respDataUser = await response.json();
    return respDataUser;
  } catch (error) {
    console.log('error: ', error);
  }
};

const saveCustomMenu = async (userData, userId) => {
    let respRegister = {};
    try {
      const integrationName = localStorage.getItem('integrationName')
      const token = localStorage.getItem('token');
      const options = {
        method: "POST",
        body: JSON.stringify(userData),
        headers:{
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      };
      const response = await fetch(`${WEB_API}/customMenu/${userId}?` + new URLSearchParams({
        integration: integrationName
      }), options);
      if (response.status === 200) {
        respRegister = await response.json();
      }
      return respRegister;
    } catch (error) {
      console.log('error: ', error);
    }
};

const getCustomMenu = async (userId) => {
    let respDataUser = {};
    try {
      const token = localStorage.getItem('token');
      const integrationName = localStorage.getItem('integrationName')
      const options = {
        headers:{
          'Authorization': `Bearer ${token}`,
        }
      };

      const response = await fetch(`${WEB_API}/customMenu/${userId}?` + new URLSearchParams({
        integration: integrationName
      }), options);
      if (response.status === 200) {
        respDataUser = await response.json();
      }
      return respDataUser;
    } catch (error) {
      console.log('error: ', error);
    }
};

const setLoginStatus = async () => {
  try {
    const token = localStorage.getItem('token');
    const integrationName = localStorage.getItem('integrationName')
    const options = {
      method: "PUT",
      headers:{
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    };

    const response = await fetch(`${WEB_API}/usersApp/setlogin?`+ new URLSearchParams({
      integration: integrationName
    }), options);
  } catch (error) {
    console.log(error)
  }
}

export default {
    dataUserByClient,
    getAdByClientID,
    saveCustomMenu,
    getCustomMenu,
    setLoginStatus
};