import React from "react";
import { Clear } from "@material-ui/icons";

import Card from "./Card";
import Menu from "./Menu";

const Sidebar = ( props ) => {
  const { userCurrent } = props;
  const { names, lastName, email } = userCurrent;
  const onClick = (e) => {
    e.preventDefault();
    const idButton = e.target.id;
    if ( idButton === "close-menu" ) {
      const elementMenu = document.querySelector("#sidebar-left");
      elementMenu.style.display = "none";
      elementMenu.classList.remove("show");
    }
  };

  return (
    <div
      id="sidebar-left"
      className="modal fade left"
      style={{display: "none"}}
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog modal-sm" role="document">
        <div className="modal-content">
          <div className="modal-header bg-nutrivital d-inline-block hamburguer-menu-sidebar">
            <button
              id="close-menu"
              type="button"
              className="btn btn-nutrivital mt-3 mb-3"
              data-dismiss="modal"
              onClick={onClick}
            >
              <Clear style={{color: "white", pointerEvents: "none"}} />
            </button>
            <Card
              names={names}
              lastName={lastName}
              email={email}
            />
          </div>
          <div className="modal-body p-0">
            <Menu />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
