import React from "react";
import { Redirect ,Route, Switch } from "react-router-dom";

import AdminLayout from "./AdminLayout";
import AuthLayout from "./AuthLayout";
import FormsLayout from "./FormsLayout";
import PagesLayout from "./PagesLayout";
import { NotFound } from "../pages";
import { PrivateRoute } from "../authenticate/PrivateRoute";

import "../../stylesheets/authLayout.scss";
import "../../stylesheets/customhtml.scss";
import "../../stylesheets/formsLayout.scss";
import "../../stylesheets/partials.scss";

const Layouts = (props) => {
  return (
    <>
      {/*<Redirect exact from="/" to="/auth/login" />*/}
      <Switch>
        <PrivateRoute
          path="/admin"
          component={(props) => <AdminLayout {...props} />}
        />
        <Route path="/auth" component={(props) => <AuthLayout {...props} />} />
        <PrivateRoute
          path="/forms"
          component={(props) => <FormsLayout {...props} />}
        />
        <Route
          path="/pages"
          component={(props) => <PagesLayout {...props} />}
        />
        <Route component={(props) => <NotFound {...props} />} />
      </Switch>
    </>
  );
};

export default Layouts;
