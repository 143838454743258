import React, {useState, useEffect} from "react";
import { Menu } from "@material-ui/icons";

import ProgressIndicator from "./ProgressIndicator";

const HeaderNavApp = ( props ) => {
  const { location } = props;
  let showProgressIndicator = false;

  const [hasMenu, setHasMenu] = useState(
    JSON.parse(localStorage.getItem('user')).hasMenu || false
  )

  if(location.pathname !== "/forms/shouldEat"
  && location.pathname !== "/forms/currentResult"
  && location.pathname !== "/forms/menuDesign"
  && location.pathname !== "/forms/nutrivitalPlan") {
    showProgressIndicator = true;
  } else {
    showProgressIndicator = false;
  }

  const onClick = (e) => {
    e.preventDefault();
    const idButton = e.target.id;
    if ( idButton === "open-menu" ) {
      const elementMenu = document.querySelector("#sidebar-left");
      elementMenu.style.display = "block";
      elementMenu.classList.add("show");
    }
  };

  useEffect(() => {
    const {hasMenu} = JSON.parse(localStorage.getItem('user'))
    setHasMenu(hasMenu || location.pathname == "/forms/nutrivitalPlan")
  }, [])

  const ActionButton = () => {
    return location.pathname === "/forms/nutrivitalPlan"
      ?
        <a className="nav-link btn btn-nutrivital btn-outline-light" href="#/forms/health">
          Nueva evaluación
        </a>
      :
        <a className="nav-link btn btn-nutrivital btn-outline-light" href="#/forms/nutrivitalPlan">
          Mi menú
        </a>
  }

  return (
    <nav id="navigation-app" className="navbar navbar-default navbar-static-top bg-nutrivital nav-bar">
      {
        hasMenu &&
          <div className="col-2 d-flex justify-content-center align-items-center hamburguer-menu">
              {/* <button
                id="open-menu"
                type="button"
                className="btn btn-nutrivital"
                data-toggle="modal"
                data-target="#sidebar-left"
                onClick={onClick}
              >
                <Menu style={{color: "white", pointerEvents: "none"}} />
              </button> */}
            <ActionButton></ActionButton>
          </div>
        }
      <div className="col-10">
        {showProgressIndicator ? <ProgressIndicator {...props} /> : null}
      </div>
    </nav>
  );
};

export default HeaderNavApp;
