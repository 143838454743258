import Loadable from "@loadable/component";

import { Loading } from "../partials";

const CurrentResult = Loadable(() => import("./CurrentResult"), { Loading });
const Feeding = Loadable(() => import("./Feeding"), { Loading });
const Health = Loadable(() => import("./Health"), { Loading });
const Lifestyle = Loadable(() => import("./Lifestyle"), { Loading });
const Measurements = Loadable(() => import("./Measurements"), { Loading });
const MenuDesign = Loadable(() => import("./MenuDesign"), { Loading });
const NutrivitalPlan = Loadable(() => import("./NutrivitalPlan"), { Loading });
const ShouldEat = Loadable(() => import("./ShouldEat"), { Loading });

export {
    CurrentResult,
    Feeding,
    Health,
    Lifestyle,
    Measurements,
    MenuDesign,
    NutrivitalPlan,
    ShouldEat,
};