import { getAge } from "../components/helpers/HelpersForms";
const WEB_API = process.env.API;
const API_URL = `${WEB_API}/authUsersApp`;

const register = async (userData, userId, typeUser) => {
  let respRegister = {};
  try {
    const options = {
      method: "PUT",
      body: JSON.stringify(userData),
      headers:{
        'Content-Type': 'application/json'
      }
    };
    const response = await fetch(`${WEB_API}/usersApp/user/${userId}?clientType=${typeUser}`, options);
    if (response.status === 200) {
      respRegister = await response.json();
    }
    return respRegister;
  } catch (error) {
    console.log('error: ', error);
  }
};

const sodexoRegister = async ({token, user}) => {
  try {
    const options = {
      method: "POST",
      headers:{
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(user)
    };

    const response = await fetch(
      `${WEB_API}/usersApp/sodexo/register`, options
    );
    const formated = await response.json()
    return formated
  } catch (error) {
    console.log(error)
  }
}

const searchUserApp = async (identifier, clientType) => {
  let respSignin = {};
  try {
    const response = await fetch(`${WEB_API}/usersApp/user/${identifier}?clientType=${clientType}`);
      if (response.status === 200) {
        respSignin = await response.json();
        return respSignin;
      }
      respSignin = await response.json();
      return respSignin;
  } catch (error) {
    console.log('error: ', error);
  }
};

const login = async (email, password) => {
  const userData = {
    email: email,
    password: password,
  };
  const options = {
    method: "POST",
    body: JSON.stringify(userData),
    headers:{
      'Content-Type': 'application/json'
    }
  };
  let respSignin = {};
    try {
      const response = await fetch(
        `${API_URL}/login`, options
      );
      if (response.status === 200) {
        //  localStorage.setItem("token", response.token);
        respSignin = await response.json();
        return respSignin;
      }
      respSignin = await response.json();
      return respSignin;
    } catch (error) {
      console.log('error: ', error);
    }
};

const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const getAuth = async () => {
  let isAuthenticated = false;
  if (localStorage.getItem('token') !== null) {
    const token = localStorage.getItem('token');
    const integrationName = localStorage.getItem('integrationName')

    if (token) {
      const params = integrationName
        ? {
            integration: integrationName
          }
        : {}

      const options = {
        method: "POST",
        headers:{
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params)
      };
      try {
        const response = await fetch(
          `${API_URL}/verify`, options
        );
        const dataCurrentUser = await response.json();
        dataCurrentUser.age = getAge(dataCurrentUser.dateOfBirth);
        if (response.status === 200) {
          isAuthenticated = true;
          localStorage.setItem("user", JSON.stringify(dataCurrentUser));
          return {isAuthenticated, dataCurrentUser};
        }
        logout();
      } catch (error) {
        isAuthenticated = false;
        logout();
      }
    }
    return isAuthenticated;
  }
  return isAuthenticated;
};

const recovery = async (email) => {
  const userData = {
    email: email,
  };
  const options = {
    method: "POST",
    body: JSON.stringify(userData),
    headers:{
      'Content-Type': 'application/json'
    }
  };
  let respReset = {};
  try {
    const response = await fetch(
      `${API_URL}/reset`, options
    );
    if (response.status === 200) {
      respReset = await response.json();
      return respReset;
    }
    respReset = await response.json();
    return respReset;
  } catch (error) {
    console.log('error: ', error);
  }
};

const changePass = async (pass, hash, userId) => {
  if (hash) {
    const options = {
      method: "POST",
      headers:{
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${hash}`,
      },
      body: JSON.stringify({newPassword: pass})
    };
    let respChangePass = {};
    try {
      const response = await fetch(
        `${API_URL}/changePass/${userId}`, options
      );
      if (response.status === 200) {
        respChangePass = await response.json();
        return respChangePass;
      }
      respChangePass = await response.json();
      return respChangePass;
    } catch (error) {

    }
  }
};

const verifyToken = async(token) => {
  try {
    const options = {
      method: "GET",
      headers:{
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    };

    let response = await fetch(`${API_URL}/integration`, options)

    if (response.status === 200) {
      response = await response.json();
      return response;
    }
  } catch (error) {
    console.log(error)
  }
}

export default {
  register,
  searchUserApp,
  login,
  logout,
  getCurrentUser,
  getAuth,
  recovery,
  changePass,
  verifyToken,
  sodexoRegister
};