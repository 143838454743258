import bcryptjs from "bcryptjs";
/*** format input text card digits 0000-0000-0000-0000 ***/
const cardDigitsFormat = (value) => {
    const cardFormat = value.match(/.{1,4}/g).join('-');
    return cardFormat;
};
/*** encrypt data ***/
const encryptPass = async ( passString ) => {
    const salt = await bcryptjs.genSalt(10);
    return bcryptjs.hash(passString, salt);
};
/*** format date yyyy-mm-dd ***/
const getFormatDate = (date) => {
    const formatDate = new Date(date).toISOString().slice(0,10);
    return date;
}

/*** pattern valid email ***/
const validEmail = (email) => {
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    let matchEmail = [];
    matchEmail = regex.exec(email);
    if (matchEmail !== null) {
        const { input } = matchEmail;
        return input;
    }
    return null;
}

const validPassword = (pass) => {
    //  const regex = /^(?!.*[\s])(?=(?:[^A-Z]*[A-Z]*[^a-z]*[a-z]){1})(?=(?:[^0-9]*[0-9]){1})(?=(?:[^#?!@$%+\-\_".|°¬&\/\\()='¡¿*~{}[\]^`,;:<>]*[#?!@$%+\-\_".|°¬&\/\\()='¡¿*~{}[\]^`,;:<>]{1})).{8,}$/g;
    const regex = /^(?=.*).{6,}/g;
    let matchPass = regex.exec(pass);
    if (matchPass !== null) {
        const { input } = matchPass;
        return input;
    }
    return null;
};

const getAge = (dateIsoString) => {
    const dateOfBirth = dateIsoString;
    const parts = dateOfBirth.split("T");
    const birthDateISO = parts[0];

    const birthDate =  new Date(birthDateISO);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();

    if(today.getMonth() < birthDate.getMonth()) {
        age--;
    }

    if(today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate()) {
        age--;
    }
    return age;
};

//  round results calculations up .4 to decimal
const roundCalc = (float) => {
    const round = Math.round(float);
    return round;
};

//  thousand separations
const formatKNumbers = (number) => {
    let numFormat = 0;
    const exp = /(\d)(?=(\d{3})+(?!\d))/g;
    const rep = '$1,';
    numFormat = number.toString().replace(exp,rep);
    return numFormat;
};

/*** mask digits card ***/
const maskDigits = (cardDigits) => {
    let firstDigits = '';
    let lastDigits = '';
    let maskDigits = '';

    firstDigits = cardDigits.substr(0,6);
    lastDigits = cardDigits.substr(12,4);
    let i = 0;
    for (i = 0; i < cardDigits.length - 6; ++i ) {
        if (i <= 5) {
            firstDigits += '*';
        }
    }
    maskDigits = `${firstDigits}${lastDigits}`;
    /*let maskDigits = cardDigits;
    if (cardDigits.length === 4) {
        maskDigits =  `${maskDigits}-`;
    } else if (cardDigits.length === 9) {
        maskDigits =  `${maskDigits}-`;
    } else if (cardDigits.length === 14) {
        maskDigits =  `${maskDigits}-`;
    } else if (cardDigits.length === 15 || cardDigits.length === 10 || cardDigits.length === 5) {
        maskDigits = maskDigits.replace(/-+$/g, '');
    }*/
    return maskDigits;
};

export {
    cardDigitsFormat,
    encryptPass,
    getFormatDate,
    validEmail,
    validPassword,
    getAge,
    roundCalc,
    formatKNumbers,
    maskDigits,
};
