import React, {useState, useEffect} from "react";
import AuthServices from "../../services/auth.services";

const closeSession = () => {
  AuthServices.logout();
};

const Menu = ( props ) => {
  const [isIntegration, setIsIntegration] = useState(false)

  useEffect(() => {
    const isIntegration = localStorage.getItem('integrationName')
    setIsIntegration(!!isIntegration)
  }, [])

  return (
    <ul className="nav flex-column">
      <li className="nav-item">
        <a className="nav-link active" href="#/forms/nutrivitalPlan">
          Mi menú
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#/pages/welcome">
          Nueva evaluación
        </a>
      </li>
      {
        !isIntegration &&
        <li className="nav-item">
          <a className="nav-link" href="#/auth/login" onClick={closeSession}>
            Cerrar sesión
          </a>
        </li>
      }
    </ul>
  );
};

export default Menu;
