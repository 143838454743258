import Loadable from "@loadable/component";

import { Loading } from "../partials";

const DashboardAdmin = Loadable(() => import("./DashboardAdmin"), { Loading });

const DocumentViewer = Loadable(() => import("./DocumentViewer"), { Loading });

const MenuPrint = Loadable(() => import("./MenuPrint"), { Loading });

const NotFound = Loadable(() => import("./NotFound"), { Loading });

const Privacy = Loadable(() => import("./Privacy"), { Loading });

const Terms = Loadable(() => import("./Terms"), { Loading });

const Welcome = Loadable(() => import("./Welcome"), { Loading });

export {
  DashboardAdmin,
  DocumentViewer,
  MenuPrint,
  NotFound,
  Privacy,
  Terms,
  Welcome,
};
